export default {
  header:{
    home:'หน้าแรก',
    list:'รายการ',
    history:'ข้อมูลย้อนหลัง',
    download:'ดาวน์โหลด',
    faq:'คำถามที่พบบ่อย',
    info:'เกี่ยวกับคุณภาพอากาศ'
  },
  footer:{
    header:'กรมควบคุมมลพิษ',
    address:'92 ซอยพหลโยธิน 7 ถนนพหลโยธิน แขวงพญาไท เขตพญาไท กรุงเทพฯ 10400',
    link_hd:'ลิงค์ที่เกี่ยวข้อง',
    noise_web:'Noise Monitor เว็บรายงานค่าระดับเสียงในประเทศไทย',
    erg4thai_web:'Erg4Thai เว็บคู่มือระงับเหตุฉุกเฉินเบื้องต้น',
    hotline:'สายด่วน',
    contact:'ติดต่อเรา',
    facebook:'กรมควบคุมมลพิษ'
  },
  region:{
    all:'ทั่วประเทศ',
    bangkok:'กรุงเทพฯ<br>และปริมณฑล',
    north:'ภาคเหนือ',
    north_east:'ภาคตะวันออกเฉียงเหนือ',
    central:'ภาคกลาง<br>และตะวันตก',
    east:'ภาคตะวันออก',
    sout:'ภาคใต้',
    naphralan:'พื้นที่ ต.หน้าพระลาน'
  },
  statusbar:{
    verygood:'ดีมาก',
    good:'ดี',
    moderate:'ปานกลาง',
    startaffect:'เริ่มมีผลกระทบต่อสุขภาพ',
    affecthealth:'มีผลกระทบต่อสุขภาพ'
  },
  header_region:'รายงานสภาพอากาศตามภูมิภาค',
  header_history:'ข้อมูลย้อนหลัง',
  no_data:'ไม่มีข้อมูล',
  start_date:'วันที่เริ่มต้น',
  end_date:'วันที่สิ้นสุด',
  download:{
    header:'ดาวน์โหลดแอป',
    detail:'ตรวจสอบสภาพอากาศรอบตัวคุณง่ายๆ ได้แล้วกับแอป <br>Air4Thai ดาวน์โหลดตอนนี้เลย'
  },
  faq_header:'คำถามที่พบบ่อย',
  list:'รายการ',
  airquality_area:'สถานการณ์คุณภาพอากาศ พื้นที่',
  airquality:'คุณภาพอากาศอยู่ในระดับ',
  info:{
    header:'ข้อมูลดัชนีคุณภาพอากาศ',
    c1_header:'ดัชนีคุณภาพอากาศ (Air Quality Index : AQI)',
    c1_p:'เป็นการรายงานข้อมูลคุณภาพอากาศในรูปแบบที่ง่ายต่อความเข้าใจของประชาชนทั่วไป เพื่อเผยแพร่ประชาสัมพันธ์ให้สาธารณชนได้รับทราบถึงสถานการณ์มลพิษทางอากาศในแต่ละพื้นที่ว่าอยู่ในระดับใด มีผลกระทบต่อสุขภาพอนามัยหรือไม่ ดัชนีคุณภาพอากาศ 1 ค่า ใช้เป็นตัวแทนค่าความเข้มข้นของสารมลพิษทางอากาศ 6 ชนิด ได้แก่',
    c1_p_1:'<strong>ฝุ่นละอองขนาดไม่เกิน 2.5 ไมครอน (PM<sub>2.5</sub>)</strong> เป็นฝุ่นที่มีเส้นผ่านศูนย์กลางไม่เกิน 2.5 ไมครอน เกิดจากการเผาไหม้ทั้งจากยานพาหนะ การเผาวัสดุการเกษตร ไฟป่า และกระบวนการอุตสาหกรรม สามารถเข้าไปถึงถุงลมในปอดได้ เป็นผลทําให้เกิดโรคในระบบทางเดินหายใจ และโรคปอดต่างๆ หากได้รับในปริมาณมากหรือเป็นเวลานานจะสะสมในเนื้อเยื่อปอด ทําให้การทํางานของปอดเสื่อมประสิทธิภาพลง ทําให้หลอดลมอักเสบ มีอาการหอบหืด',
    c1_p_2:'<strong>ฝุ่นละอองขนาดไม่เกิน 10 ไมครอน (PM<sub>10</sub>)</strong> เป็นฝุ่นที่มีขนาดเส้นผ่านศูนย์กลางไม่เกิน 10 ไมครอน เกิดจากการเผาไหม้เชื้อเพลิง การเผาในที่โล่ง กระบวนการอุตสาหกรรม การบด การโม่ หรือการทําให้เป็นผงจากการก่อสร้าง ส่งผลกระทบต่อสุขภาพเนื่องจากเมื่อหายใจเข้าไปสามารถเข้าไปสะสมในระบบทางเดินหายใจ',
    c1_p_3:'<strong>ก๊าซโอโซน (O<sub>3</sub>)</strong> เป็นก๊าซที่ไม่มีสีหรือมีสีฟ้าอ่อน มีกลิ่นฉุน ละลายน้ำได้เล็กน้อย เกิดขึ้นได้ทั้งในระดับบรรยากาศชั้นที่สูงจากผิวโลก และระดับชั้นบรรยากาศผิวโลกที่ใกล้พื้นดิน ก๊าซโอโซนที่เป็นสารมลพิษทางอากาศคือก๊าซโอโซนในชั้นบรรยากาศผิวโลก เกิดจากปฏิกิริยาระหว่างก๊าซออกไซด์ของไนโตรเจน และสารประกอบอินทรีย์ระเหยง่าย โดยมีแสงแดดเป็นตัวเร่งปฏิกิริยา มีผลกระทบต่อสุขภาพ โดยก่อให้เกิดการระคายเคืองตาและระคายเคืองต่อระบบทางเดินหายใจและเยื่อบุต่างๆ ความสามารถในการทำงานของปอดลดลง เหนื่อยเร็ว โดยเฉพาะในเด็ก คนชรา และคนที่เป็นโรคปอดเรื้อรัง',
    c1_p_4:'<strong>ก๊าซคาร์บอนมอนอกไซด์ (CO)</strong> เป็นก๊าซที่ไม่มีสี กลิ่น และรส เกิดจากการเผาไหม้ที่ไม่สมบูรณ์ของเชื้อเพลิงที่มีคาร์บอนเป็นองค์ประกอบ ก๊าซนี้สามารถสะสมอยู่ในร่างกายได้โดยจะไปรวมตัวกับฮีโมโกลบินในเม็ดเลือดแดงได้ดีกว่าออกซิเจนประมาณ 200-250 เท่า เมื่อหายใจเข้าไปทำให้ก๊าซชนิดนี้จะไปแย่งจับกับฮีโมโกลบินในเลือด เกิดเป็นคาร์บอกซีฮีโมโกลบิน (CoHb) ทำให้การลำเลียงออกซิเจนไปสู่เซลล์ต่างๆ ของร่างกายลดน้อยลง ส่งผลให้ร่างกายเกิดอาการอ่อนเพลีย และหัวใจทำงานหนักขึ้น',
    c1_p_5:'<strong>ก๊าซไนโตรเจนไดออกไซด์ (NO<sub>2</sub>)</strong> เป็นก๊าซที่ไม่มีสีและกลิ่น ละลายน้ำได้เล็กน้อย มีอยู่ทั่วไปในธรรมชาติ หรือเกิดจากการกระทำของมนุษย์ เช่น การเผาไหม้เชื้อเพลิงต่างๆ อุตสาหกรรมบางชนิด เป็นต้น ก๊าซนี้มีผลต่อระบบการมองเห็นและผู้ที่มีอาการหอบหืดหรือ โรคเกี่ยวกับทางเดินหายใจ',
    c1_p_6:'<strong>ก๊าซซัลเฟอร์ไดออกไซด์ (SO<sub>2</sub>)</strong> เป็นก๊าซที่ไม่มีสี หรืออาจมีสีเหลืองอ่อนๆ มีรสและกลิ่นที่ระดับความเข้มข้นสูง เกิดจากธรรมชาติและการเผาไหม้เชื้อเพลิงที่มีกำมะถัน (ซัลเฟอร์) เป็นส่วนประกอบ สามารถละลายน้ำได้ดี สามารถรวมตัวกับสารมลพิษอื่นแล้วก่อตัวเป็นอนุภาคฝุ่นขนาดเล็กได้ ก๊าซนี้มีผลกระทบโดยตรงต่อสุขภาพ ทำให้เกิดการระคายเคืองต่อเยื่อบุตา ผิวหนัง และระบบทางเดินหายใจ หากได้รับเป็นเวลานาน ๆ จะทำให้เป็นโรคหลอดลมอักเสบเรื้อรังได้',
    c2_p:'ดัชนีคุณภาพอากาศของประเทศไทยแบ่งเป็น 5 ระดับ คือ ตั้งแต่ 0 ถึง 201 ขึ้นไป ซึ่งแต่ละระดับจะใช้สีเป็นสัญญลักษณ์เปรียบเทียบระดับของผลกระทบต่อสุขภาพอนามัย <a href="#table_1">(ตารางที่ 1)</a> โดยดัชนีคุณภาพอากาศ 100 จะมีค่าเทียบเท่ามาตรฐานคุณภาพอากาศในบรรยากาศโดยทั่วไป หากดัชนีคุณภาพอากาศมีค่าสูงเกินกว่า 100 แสดงว่าค่าความเข้มข้นของมลพิษทางอากาศมีค่าเกินมาตรฐานและคุณภาพอากาศในวันนั้นจะเริ่มมีผลกระทบต่อสุขภาพอนามัยของประชาชน',
    t1_header:'ตารางที่ 1 เกณฑ์ของดัชนีคุณภาพอากาศของประเทศไทย',
    t1_m1:'ดีมาก',
    t1_m2:'ดี',
    t1_m3:'ปานกลาง',
    t1_m4:'เริ่มมีผลกระทบต่อสุขภาพ',
    t1_m5:'มีผลกระทบต่อสุขภาพ',
    t1_n1:'ประชาชนทุกคนสามารถดำเนินชีวิตได้ตามปกติ',
    t1_n2:'<strong>ประชาชนทั่วไป :</strong> สามารถทำกิจกรรมกลางแจ้งได้ตามปกติ<br /><strong>ประชาชนกลุ่มเสี่ยง :</strong> ควรสังเกตอาการผิดปกติ เช่น ไอบ่อย หายใจลำบาก หายใจถี่ หายใจไม่ออก หายใจมีเสียงวี้ด แน่นหน้าอก เจ็บหน้าอก ใจสั่น คลื่นไส้ เมื่อยล้าผิดปกติ หรือ วิงเวียนศีรษะ',
    t1_n3:'<strong>ประชาชนทั่วไป :</strong> ลดระยะเวลาการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก<br /><strong>ประชาชนกลุ่มเสี่ยง :</strong><br />-	ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM<sub>2.5</sub> ทุกครั้ง  ที่ออกนอกอาคาร<br />- ลดระยะเวลาการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก <br />- หากมีอาการผิดปกติให้รีบปรึกษาแพทย์',
    t1_n4:'<strong>ประชาชนทั่วไป :</strong><br />- ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM<sub>2.5</sub> ทุกครั้งที่ออกนอกอาคาร<br />- จำกัดระยะเวลาในการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก<br />- ควรสังเกตอาการผิดปกติ เช่น ไอ หายใจลำบาก ระคายเคืองตา<br /><strong>ประชาชนกลุ่มเสี่ยง :</strong><br />- ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM<sub>2.5</sub> ทุกครั้งที่ออกนอกอาคาร<br />-	เลี่ยงการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก<br />- ให้ปฏิบัติตามคำแนะนำของแพทย์ หากมีอาการผิดปกติให้รีบไปพบแพทย์',
    t1_n5: '<strong>ประชาชนทุกคน</strong><br />- งดกิจกรรมกลางแจ้ง<br />-	หากมีความจำเป็นต้องทำกิจกรรมกลางแจ้งให้ใช้อุปกรณ์ป้องกันตนเองทุกครั้ง เช่น หน้ากากป้องกัน PM<sub>2.5</sub><br />-	หากมีอาการผิดปกติให้รีบไปพบแพทย์<br />-  ผู้ที่มีโรคประจำตัว ควรอยู่ในพื้นที่ปลอดภัยจากมลพิษทางอากาศ  ให้เตรียมยาและอุปกรณ์ที่จำเป็นให้พร้อมและปฏิบัติตามคำแนะนำของแพทย์อย่างเคร่งครัด',
    c3_header:'การคำนวณดัชนีคุณภาพอากาศรายวันของสารมลพิษทางอากาศแต่ละประเภท',
    c3_p_1:'คำนวณจากค่าความเข้มข้นของสารมลพิษทางอากาศจากข้อมูลผลการตรวจวัดคุณภาพอากาศ โดยมีระดับของค่าความเข้มข้นของสารมลพิษทางอากาศที่เทียบเท่ากับค่าดัชนีคุณภาพอากาศที่ระดับต่างๆ ดัง <a href="#table_2">(ตารางที่ 2)</a> การคำนวณดัชนีคุณภาพอากาศภายในช่วงระดับ เป็นสมการเส้นตรง ดังนี้',
    formula_header:'กำหนดให้',
    formula_p:'I = ค่าดัชนีย่อยคุณภาพอากาศ<br>X = ความเข้มข้นของสารมลพิษทางอากาศจากการตรวจวัด<br>X<sub>i</sub> , X<sub>j</sub> = ค่าต่ำสุด, สูงสุด ของช่วงความเข้มข้นสารมลพิษที่มีค่า X<br>I<sub>i</sub> , I<sub>j</sub> = ค่าต่ำสุด, สูงสุด ของช่วงดัชนีคุณภาพอากาศที่ตรงกับช่วงความเข้มข้น X จากค่าดัชนีย่อยที่คำนวณได้ สารมลพิษทางอากาศประเภทใดมีค่าดัชนีสูงสุด<br>จะใช้เป็นดัชนีคุณภาพอากาศ (AQI) ณ ช่วงเวลานั้น',
    t2_header:'ตารางที่ 2 ค่าความเข้มข้นของสารมลพิษทางอากาศที่เทียบเท่ากับค่าดัชนีคุณภาพอากาศ',
    t2_pm25:'PM<sub>2.5</sub><br>(มคก./ลบ.ม.)',
    t2_pm10:'PM<sub>10</sub><br>(มคก./ลบ.ม.)',
    t2_24hr:'เฉลี่ย 24 ชั่วโมงต่อเนื่อง',
    t2_8hr:'เฉลี่ย 8 ชั่วโมงต่อเนื่อง',
    t2_1hr:'เฉลี่ย 1 ชั่วโมง',
    t2_header_dis:'ช่วงเวลาเฉลี่ย และหน่วยสารมลพิษทางอากาศที่ใช้ในการคำนวน',
    t2_dis_1:'PM<sub>2.5</sub> เฉลี่ย 24 ชั่วโมงต่อเนื่อง : ไมโครกรัมต่อลูกบาศก์เมตร หรือ มคก./ลบ.ม. หรือ µg./m<sup>3</sup>',
    t2_dis_2:'PM<sub>10</sub> เฉลี่ย 24 ชั่วโมงต่อเนื่อง : ไมโครกรัมต่อลูกบาศก์เมตร หรือ มคก./ลบ.ม. หรือ µg./m<sup>3</sup>',
    t2_dis_3:'O<sub>3</sub> เฉลี่ย 8 ชั่วโมงต่อเนื่อง : ส่วนในพันล้านส่วน หรือ ppb หรือ 1/1,000,000,000',
    t2_dis_4:'CO เฉลี่ย 8 ชั่วโมงต่อเนื่อง : ส่วนในล้านส่วน หรือ ppm หรือ 1/1,000,000',
    t2_dis_5:'NO<sub>2</sub> เฉลี่ย 1 ชั่วโมง : ส่วนในพันล้านส่วน หรือ ppb หรือ 1/1,000,000,000',
    t2_dis_6:'SO<sub>2</sub> เฉลี่ย 1 ชั่วโมง : ส่วนในพันล้านส่วน หรือ ppb หรือ 1/1,000,000,000',
    t3_header:'ทดลองคำนวนค่าดัชนีคุณภาพอากาศ',
    t3_th_1:'พารามิเตอร์',
    t3_th_2:'ค่าเฉลี่ย',
    t3_th_3:'ค่า Index',
    t3_th_4:'ระดับสี',
    t3_td_1:'PM<sub>2.5</sub> เฉลี่ย 24 ชั่วโมงต่อเนื่อง',
    t3_td_2:'PM<sub>10</sub> เฉลี่ย 24 ชั่วโมงต่อเนื่อง',
    t3_td_3:'O<sub>3</sub> เฉลี่ย 8 ชั่วโมงต่อเนื่อง',
    t3_td_4:'CO เฉลี่ย 8 ชั่วโมงต่อเนื่อง',
    t3_td_5:'NO<sub>2</sub> เฉลี่ย 1 ชั่วโมง',
    t3_td_6:'SO<sub>2</sub> เฉลี่ย 1 ชั่วโมง',
    t3_td_7: 'ค่าดัชนีคุณภาพอากาศ AQI',
    aqi: 'ค่าดัชนีคุณภาพอากาศ (AQI)',
    color: 'สีที่ใช้',
    meaning:'ความหมาย (ระดับคุณภาพอากาศ)',
    note:'ข้อควรปฏิบัติ',
  },
  all:'ทั้งหมด',
  above:'ขึ้นไป',
  over:'มากกว่า',
  download_header:'ดาวน์โหลดหนังสือรายงานสถานการณ์และการจัดการคุณภาพอากาศและเสียง',
  to:'ถึง',
  Hourly:'รายชัวโมง',
  Daily:'รายวัน',
  Monthly:'รายเดือน',
  Yearly:'รายปี',
  examine:'ตรวจสอบ',
  search:'ค้นหา',
  download_bt:'ดาวน์โหลด'
}
