import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './filter'

import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import Highcharts from 'highcharts';
import VueHighcharts from 'vue-highcharts';
import loadHighchartsMore from 'highcharts/highcharts-more.js';
import Exporting from "highcharts/modules/exporting";

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment-timezone'
import VueI18n from 'vue-i18n'
import en from './data/lang/en/lang'
import th from './data/lang/th/lang'
const messages = {
  en,
  th
}


Vue.config.productionTip = false

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

loadHighchartsMore(Highcharts);
Vue.use(VueAxios, axios)
Exporting(Highcharts)
Vue.use(VueHighcharts)
Vue.use(VueSweetalert2)
Vue.use(VueI18n)
Vue.use(require('vue-moment'), {moment})
Vue.use(Datetime)

const i18n = new VueI18n({
  locale: 'th',
  messages
})

import { Settings } from 'luxon'
Settings.defaultLocale = 'th'
// Settings.i18n = i18n

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
