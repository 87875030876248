import Vue from 'vue'
import Router from 'vue-router'

const Home = () => import('../components/views/Home.vue')
const Map = () => import('../components/views/Map.vue')
const Report = () => import('../components/views/Report.vue')
const History = () => import('../components/views/History.vue')
const Chart = () => import('../components/views/Chart.vue')
const Download = () => import('../components/views/Download.vue')
const Faq = () => import('../components/views/Faq.vue')
const StationDetail = () => import('../components/views/Station_detail.vue')
const AQIInfo = () => import('../components/views/Aqi_info.vue')
Vue.use(Router)

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
});


export default router;

function configRoutes () {
    return [
      {
        path: '/',
        redirect: '/Home',
        // name: 'Home',
        // component: Home,
      },
      {
        path: '/Home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/Map',
        name: 'Map',
        component: Map,
      },
      {
        path: '/Report',
        name: 'Report',
        component: Report,
      },
      {
        path: '/History',
        name: 'History',
        component: History,
      },
      {
        path: '/Download',
        name: 'Download',
        component: Download
      },
      {
        path: '/Chart',
        name: 'Chart',
        component: Chart,
      },
      {
        path: '/StationDetail',
        name: 'StationDetail',
        component: StationDetail
      },
      {
        path: '/Faq',
        name: 'Faq',
        component: Faq,
      },
      {
        path: '/AQIInfo',
        name: 'AQIinfo',
        component: AQIInfo
      }
    ]
  }