export default {
  header:{
    home:'Home',
    list:'List',
    history:'Archived Data',
    download:'Download',
    faq:'FAQ',
    info:'About air quality'
  },
  footer:{
    header:'Pollution Control Department',
    address:'92 Soi Phahonyothin 7, Phahonyothin Road, Phayathai, Phayathai, Bangkok 10400',
    link_hd:'Related',
    noise_web:'Noise Monitoring Report From Pollution Control Department',
    erg4thai_web:'Erg4Thai, Website manual for solving basic emergencies',
    hotline:'Hotline',
    contact:'Contact us',
    facebook:'Pollution Control Department'
  },
  region:{
    all:'All Provinces',
    bangkok:'Bangkok Metropolitan Region',
    north:'Northern Provinces',
    north_east:'North-Eastern Provinces',
    central:'Central Provinces',
    east:'Eastern Provinces',
    sout:'Southern Provinces',
    naphralan:'Na Phra Lan Area'
  },
  statusbar:{
    verygood:'Excellent',
    good:'Satisfactory',
    moderate:'Moderate',
    startaffect:'Unhealthy',
    affecthealth:'Very Unhealthy'
  },
  header_region:'Regional Air Quality and Situation Reports',
  header_history:'Archived Data',
  no_data:'No Data',
  start_date:'Start Date',
  end_date:'End Date',
  download:{
    header:'Download',
    detail:'Easily check the weather around you. Got it with the app <br>Air4Thai now!'
  },
  faq_header:'Frequently asked questions',
  list:'List',
  airquality_area:'Air quality situation in',
  airquality:'Level of air quality is to',
  info:{
    header:'Air quality index data',
    c1_header:'Air Quality Index : AQI',
    c1_p:'It is a report on air quality information in a format that is easy to understand for the general public. To disseminate information to the public about the level of air pollution in each area Does it affect health? One air quality index is used to represent the concentration of 6 air pollutants as follows',
    c1_p_1:'<strong>Dust with a diameter of not more than 2.5 microns (PM<sub>2.5</sub>)</strong> is a dust with a diameter of not more than 2.5 microns caused by combustion from vehicles. Burning agricultural materials, forest fires, and industrial processes can reach the air sacs in the lungs As a result, it can cause diseases in the respiratory system. and various lung diseases If given in large quantities or for a long time, it will accumulate in the lung tissue. causing the lung function to deteriorate cause bronchitis have asthma',
    c1_p_2:'<strong>Particulate matter less than 10 microns (PM<sub>10</sub>)</strong> is dust with a diameter of not more than 10 microns caused by fuel combustion. open fire Industrial processes, grinding, milling or powdering of construction Affects health because when inhaled it can accumulate in the respiratory tract.',
    c1_p_3:"<strong>Ozone gas (O<sub>3</sub>)</strong> is a colorless or light blue gas with a pungent odor. Slightly soluble in water. Occur both at high levels of the atmosphere from the earth's surface. and the level of the Earth's atmosphere near the ground Ozone gas that is an air pollutant is ozone gas in the Earth's atmosphere. caused by the reaction between nitrogen oxides and volatile organic compounds with sunlight as a catalyst affect health By causing eye irritation and irritation to the respiratory system and mucous membranes. Decreased lung capacity, tired quickly, especially in children, the elderly and people with chronic lung disease.",
    c1_p_4:'<strong>Carbon monoxide (CO)</strong> is a colorless, odorless and tasteless gas produced by the incomplete combustion of carbon-containing fuels. This gas can accumulate in the body where it combines with hemoglobin in red blood cells about 200-250 times better than oxygen. When inhaled, this gas will compete for hemoglobin in the blood. resulting in carboxyhemoglobin (CoHb) which facilitates the transport of oxygen to cells of the body is reduced As a result, the body becomes weak. and the heart works harder',
    c1_p_5:'<strong>Nitrogen dioxide (NO<sub>2</sub>)</strong> is a colorless and odorless gas. slightly soluble common in nature Or caused by human actions such as burning various fuels. some industries, etc. This gas affects the visual system and people with asthma or Respiratory disease',
    c1_p_6:'<strong>Sulfur dioxide (SO<sub>2</sub>)</strong> is a colorless gas. or may have a light yellow color It has a high concentration of flavor and smell. Caused by nature and burning fuels with sulfur (sulfur) as a component. good water solubility Able to combine with other pollutants to form fine dust particles. This gas has a direct impact on health. Cause irritation to conjunctiva, skin and respiratory system. If taken for a long time, it can cause chronic bronchitis.',
    c2_p:"Thailand's air quality index is divided into 5 levels, ranging from 0 to 201 and above, with each level using colors to symbolize the level of health impacts <a href='#table_1'>(Table 1)</a> The air quality index of 100 is equivalent to the general atmospheric air quality standard. If the air quality index is higher than 100, the air pollution concentration value is above the standard and the air quality on that day will begin to affect public health.",
    t1_header:'Table 1 Criteria of Thailand Air Quality Index',
    t1_m1:'Excellent',
    t1_m2:'Satisfactory',
    t1_m3:'Moderate',
    t1_m4:'Unhealthy',
    t1_m5:'Very Unhealthy',
    t1_n1:'Everyone can live their life normally.',
    t1_n2: '<strong>General public :</strong> able to do outdoor activities normally<br /><strong>People who need special health care :</strong> abnormal symptoms should be observed, such as frequent coughing, difficulty breathing, shortness of breath, wheezing, wheezing, chest tightness, chest pain, palpitations, nausea, unusual fatigue, or dizziness.',
    t1_n3: '<strong>General public :</strong> reduce the amount of time spent doing strenuous outdoor activities or exercises.<br /><strong>People who need special health care :</strong><br />- Use personal protective equipment such as PM<sub>2.5</sub> protective masks every time you go out.<br />- Reduce the duration of activities or outdoor exercise that requires a lot of energy.<br />- If there are abnormal symptoms, consult a doctor immediately.',
    t1_n4: '<strong>General public :</strong><br />- Use personal protective equipment such as PM<sub>2.5</sub> protective masks every time you go out.<br />- Limiting the amount of time spent doing strenuous outdoor activities or exercises.<br />- Abnormal symptoms should be observed, such as coughing, difficulty breathing, or eye irritation.<br /><strong>People who need special health care :</strong><br />- Use personal protective equipment such as PM<sub>2.5</sub> protective masks every time you go out.<br />- Avoid activities or outdoor exercise that requires a lot of energy.<br />- Follow your doctor\'s advice. If there are any abnormal symptoms, seek medical attention.',
    t1_n5: '<strong>Everyone</strong>- Should avoid outdoor activities.<br />- Use personal protective equipment such as PM<sub>2.5</sub> protective masks every time you go out.<br />- If you have any health symptoms, you should consult your doctor.<br />- People with congenital disease should be in a safe area from air pollution. Have the necessary medicines and equipment ready and strictly follow the doctor\'s advice.',
    c3_header:'Calculation of the daily air quality index of each air pollutant',
    c3_p_1:'Calculated from the concentration of air pollutants from air quality measurement results. The levels of air pollutant concentration values are equivalent to the air quality index values at various levels as shown <a href="#table_2">(Table 2)</a> is a straight line equation as follows',
    formula_header:'set to',
    formula_p:'I = Air quality sub-index<br>X = Concentration of air pollutants from the measurement<br>X<sub>i</sub> , X<sub>j</sub> = The minimum, maximum value of the pollutant concentration range with the value X.<br>I<sub>i</sub> , I<sub>j</sub> = The minimum, maximum value of the air quality index range corresponding to the concentration range X from the calculated subindex.<br>Which air pollutant has the highest index? It will be used as the Air Quality Index (AQI) at that time.',
    t2_header:'Table 2 Air Pollution Concentration Equivalent to Air Quality Index',
    t2_pm25:'PM<sub>2.5</sub><br>(mcg/cu.m.)',
    t2_pm10:'PM<sub>10</sub><br>(mcg/cu.m.)',
    t2_24hr:'Average 24 hours continuously',
    t2_8hr:'Average 8 hours continuously',
    t2_1hr:'Average 1 hour',
    t2_header_dis:'Average interval and the air pollutant unit used in the calculation',
    t2_dis_1:'PM<sub>2.5</sub> 24 hours continuous average : micrograms per cubic meter or µg./m<sup>3</sup>',
    t2_dis_2:'PM<sub>10</sub> 24 hours continuous average : micrograms per cubic meter or µg./m<sup>3</sup>',
    t2_dis_3:'O<sub>3</sub> 8 hours continuous hours average : parts per billion or ppb or 1/1,000,000,000',
    t2_dis_4:'CO 8 hour continuous average : parts per million or ppm or 1/1,000,000',
    t2_dis_5:'NO<sub>2</sub> 1 hour average : parts per billion or ppb or 1/1,000,000,000',
    t2_dis_6:'SO<sub>2</sub> 1 hour average : parts per billion or ppb or 1/1,000,000,000',
    t3_header:'Experimental calculation of air quality index.',
    t3_th_1:'Parameter',
    t3_th_2:'Average',
    t3_th_3:'Index value',
    t3_th_4:'Color',
    t3_td_1:'PM<sub>2.5</sub> 24 hours continuous average',
    t3_td_2:'PM<sub>10</sub> 24 hours continuous average',
    t3_td_3:'O<sub>3</sub> 8 hour continuous average',
    t3_td_4:'CO 8 hour continuous average',
    t3_td_5:'NO<sub>2</sub> 1 hour average',
    t3_td_6:'SO<sub>2</sub> 1 hour average',
    t3_td_7:'AQI Air Quality Index',
    color:'Color',
    note:'Note',
    meaning:'Meaning',
  },
  all:'All',
  above:'Above',
  over:'Over',
  download_header:'Download the air quality and noise management situation report book.',
  to:'to',
  Hourly:'Hourly',
  Daily:'Daily',
  Monthly:'Monthly',
  Yearly:'Yearly',
  examine:'Examine',
  search:'Search',
  download_bt:'Download'
}
