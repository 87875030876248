<template>
  <div id="app">
    <!-- <Loader/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Loader from './components/containers/Loader.vue'

export default {
  name: 'App',
  components: {
    // Loader
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Prompt');

#app {
  font-family: Prompt, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
@media only screen and (max-width : 992px) {
    .v_content{
        /* padding: 20px; */
        /* margin-top: 40px; */
        padding-top: 50px;
        color: #000000;
    }
}
@media only screen and (min-width : 992px) {
    .v_content{
        /* padding: 20px; */
        /* margin-top: 40px; */
        padding-top: 110px;
        color: #000000;
    }
}
.centered {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  color: white;
}
</style>
